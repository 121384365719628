<template>
  <div>
    <component-menu></component-menu>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mb-2">
          <h2>{{$t("lang.application.regex_tester.name")}}</h2>
          <p class="fw-light">{{$t("lang.application.regex_tester.detailed_description")}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-2">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <label for="referrenceSites" class="col-lg-12 form-label text-start">{{$t("lang.application.regex_tester.label.reference")}}</label>
                <div class="col-lg-12 text-start">
                  <a :href="`${$t('lang.application.regex_tester.url.flag')}`" target="_new" rel="noopener noreferrer" class="btn btn-warning btn-sm btn-bold mb-2">{{$t("lang.application.regex_tester.label.flag")}}</a>&nbsp;
                  <a :href="`${$t('lang.application.regex_tester.url.character_classes')}`" target="_new" rel="noopener noreferrer" class="btn btn-warning btn-sm btn-bold mb-2">{{$t("lang.application.regex_tester.label.character_classes")}}</a>&nbsp;
                  <a :href="`${$t('lang.application.regex_tester.url.assertions')}`" target="_new" rel="noopener noreferrer" class="btn btn-warning btn-sm btn-bold mb-2">{{$t("lang.application.regex_tester.label.assertions")}}</a>&nbsp;
                  <a :href="`${$t('lang.application.regex_tester.url.groups_and_ranges')}`" target="_new" rel="noopener noreferrer" class="btn btn-warning btn-sm btn-bold mb-2">{{$t("lang.application.regex_tester.label.groups_and_ranges")}}</a>&nbsp;
                  <a :href="`${$t('lang.application.regex_tester.url.quantifiers')}`" target="_new" rel="noopener noreferrer" class="btn btn-warning btn-sm btn-bold mb-2">{{$t("lang.application.regex_tester.label.quantifiers")}}</a>&nbsp;
                </div>
              </div>
              <div class="row">
                <label for="selectSample" class="col-lg-12 form-label text-start">{{$t("lang.label.sample")}}</label>
                <div class="col-lg-12">
                  <select class="form-select mb-2" id="selectSample" v-model="createForm.selected_sample" v-on:change="assignRegex">
                    <option disabled value="">{{$t("lang.hint.select")}}</option>
                    <option :value="`${sample.regex}`" v-for="(sample, key) in samples" :key="key">{{sample.name}}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <label for="inputRegex" class="col-lg-12 form-label text-start">{{$t("lang.label.regex")}}</label>
                <div class="col-lg-12">
                  <div class="input-group mb-2">
                    <span class="input-group-text">/</span>
                    <input type="text" class="form-control" id="inputRegex" v-model="createForm.regex" :placeholder="`${$t('lang.placeholder.regex')}`">
                    <span class="input-group-text">/</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <label for="inputFlag" class="col-lg-12 form-label text-start">{{$t("lang.application.regex_tester.label.flag")}}</label>
                <div class="col-lg-12">
                    <span style="margin-left:6px;">
                      <label class="checkbox-inline mb-2">
                        <input type="checkbox" v-model="createForm.flags.g" disabled>g
                      </label>
                      &nbsp;
                      <label class="checkbox-inline mb-2">
                        <input type="checkbox" v-model="createForm.flags.m">m
                      </label>
                      &nbsp;
                      <label class="checkbox-inline mb-2">
                        <input type="checkbox" v-model="createForm.flags.i">i
                      </label>
                      &nbsp;
                      <label class="checkbox-inline mb-2">
                        <input type="checkbox" v-model="createForm.flags.s">s
                      </label>
                      &nbsp;
                      <label class="checkbox-inline mb-2">
                        <input type="checkbox" v-model="createForm.flags.u">u
                      </label>
                      &nbsp;
                      <label class="checkbox-inline mb-2">
                        <input type="checkbox" v-model="createForm.flags.d">d
                      </label>
                      &nbsp;
                      <label class="checkbox-inline mb-2">
                        <input type="checkbox" v-model="createForm.flags.y">y
                      </label>
                    </span>
                </div>
              </div>
              <div class="row">
                <label for="regexTarget" class="col-lg-12 form-label text-start">{{$t("lang.label.input")}}</label>
                <div class="col-lg-12">
                  <textarea class="form-control mb-2" id="regexTarget" rows="3" v-model="createForm.target" :placeholder="`${$t('lang.placeholder.text')}`"></textarea>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 text-end">
                  <button type="button" class="btn btn-primary btn-sm mb-2" data-dismiss="modal" @click="executeProcess($event)">{{$t("lang.label.execute")}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-2" id="process-result">
          <div v-if="processResults.loaded === true">
            <div class="card mb-2" v-for="(target, key) in resultForm.targets" :key="key">
              <div class="card-body">
                <div class="row">
                  <label :for="`process-result-text-${key}`" class="col-lg-12 form-label text-start">
                    <span v-if="parseInt(key) == 0">{{$t("lang.application.regex_tester.label.output.match")}}</span>
                    <span v-else>{{$t("lang.application.regex_tester.label.output.group")}} {{parseInt(key) + 1}}</span>
                  </label>
                  <div class="col-lg-12">
                    <textarea class="form-control mb-2" :id="`process-result-text-${key}`" rows="3" v-model="resultForm.texts[key]" readonly></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 text-end">
                    <button type="button" class="btn btn-primary btn-sm mb-2" @click="listFilter(key, 'unique')">{{$t("lang.application.regex_tester.label.result_unique")}}</button>&nbsp;
                    <button type="button" class="btn btn-primary btn-sm mb-2" @click="listFilter(key, 'duplicate')">{{$t("lang.application.regex_tester.label.result_duplicate_only")}}</button>&nbsp;
                    <button type="button" class="btn btn-primary btn-sm mb-2" @click="listFilter(key, 'duplicate_unique')">{{$t("lang.application.regex_tester.label.result_duplicate_unique")}}</button>&nbsp;
                    <button type="button" class="btn btn-primary btn-sm mb-2" @click="listFilter(key, 'default')">{{$t("lang.application.regex_tester.label.result_all")}}</button>&nbsp;
                    <button type="button" class="btn btn-info btn-sm mb-2" @click="executeProcessAfter($event, key, 'copy')">{{$t("lang.label.copy")}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="card">
              <div class="card-body">
                <div class="alert alert-info" role="alert">{{$t("lang.alert.result")}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component-footer></component-footer>
  </div>
</template>

<script>
  import Vue from 'vue';
  import $ from 'jquery';
  //import createFormCheatSheet from '../../public/documents/regex_checker_cheat_sheet.html';
  export default {
    metaInfo : {
      title: `${window.application.title} ${window.application.lang.application.regex_tester.name}`,
      meta: [
        {name: 'description', conent: `${window.application.lang.application.regex_tester.meta_description}`}
      ]
    },
    head: {
      link: [
        {rel: 'alternate', hreflang: 'en', href: 'https://ezeful.app/en/regextester'},
        {rel: 'alternate', hreflang: 'es', href: 'https://ezeful.app/es/regextester'},
        {rel: 'alternate', hreflang: 'hi', href: 'https://ezeful.app/hi/regextester'},
        {rel: 'alternate', hreflang: 'ja', href: 'https://ezeful.app/ja/regextester'},
        {rel: 'alternate', hreflang: 'zh-cn', href: 'https://ezeful.app/zh_cn/regextester'},
        {rel: 'alternate', hreflang: 'zh-tw', href: 'https://ezeful.app/zh_tw/regextester'},
      ],
      //script: [
      //  { src: window.application.adsense_script, async: true, crossorigin:"anonymous"},
      //],
    },
    data () {
      return {
        lang: window.application.lang,
        samples: {
          ipv4_address: {
            name: "IPv4 Address",
            regex: "\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}"
          },
          ipv6_address: {
            name: "IPv6 Address",
            regex: "(([a-fA-F0-9]{1,4})?:)?(([a-fA-F0-9]{1,4})?:)?(([a-fA-F0-9]{1,4})?:)?(([a-fA-F0-9]{1,4})?:)?(([a-fA-F0-9]{1,4})?:)?([a-fA-F0-9]{1,4})?:([a-fA-F0-9]{1,4})?:((\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})|[a-fA-F0-9]{1,4})?"
          },
          email_address: {
            name: "Email Address",
            regex: "[a-zA-Z0-9][a-zA-Z0-9_\\.\\-]+@([a-zA-Z0-9][a-zA-Z0-9\\-]+\\.)+[a-zA-Z0-9\\-]+[a-zA-Z]"
          },
          email_domain: {
            name: "Email Domain",
            regex: "@(([a-zA-Z0-9][a-zA-Z0-9\\-]+\\.)+[a-zA-Z0-9\\-]+[a-zA-Z])"
          }
        },
        createForm: {
          selected_sample: "",
          regex: "",
          flags: {
            d: false,
            g: true,
            i: true,
            m: true,
            s: false,
            u: true,
            y: false,
          },
          target: ""
        },
        resultForm: {
          targets: [],
          texts: []
        },
        processResults: {
          loaded: false,
          length: 0,
        }
      }
    },
    created() {
      Vue.component('component-menu', require('../components/Menu.vue').default);
      Vue.component('component-footer', require('../components/Footer.vue').default);
    },
    mounted() {
      //let self = this
    },
    updated() {
      //let self = this
    },
    methods: {
      assignRegex () {
        this.createForm.regex = this.createForm.selected_sample
      },
      executeProcess(event) {
        let self = this;
        let obj = window.button.loading(event);
        window.display.loading(self.processResults);

        self.$set(self.resultForm, 'targets', []);

        try {
          
          let flags = '';
          for (let i in self.createForm.flags) {
            if (self.createForm.flags[i] === true) {
              flags += i;
            }
          }

          let regex = self.createForm.regex;
          let target = self.createForm.target;
          let re = new RegExp(regex, flags);
          //let matches = target.match(re);
          let matches = [...target.matchAll(re)];
          matches = JSON.parse(JSON.stringify(matches));

          for (let i in matches) {
            for (let i2 in matches[i]) {
              if (typeof self.resultForm.targets[i2] === "undefined") {
                self.$set(self.resultForm.targets, i2, []);
              }
              self.resultForm.targets[i2].push(matches[i][i2]);
            }
          }

        } catch(e) {
          self.$set(self.resultForm.targets, 0, []);
          self.resultForm.targets[0][0] = self.lang.validation.unknown;
        }
        
        for (let i in self.resultForm.targets) {
          self.listFilter(i, 'default')
        }

        window.display.reset(self.processResults);
        window.button.reset(obj);
        window.location.element('#process-result');
      },
      executeProcessAfter(event, key, process) {
        let self = this;
        let obj = window.button.loading(event);
        window.display.loading(self.processResults);
        try {
          if (process == 'copy') {
            self.copy(key);
          }
        } catch(e) {
          self.resultForm.targets = [];
          self.resultForm.targets[0] = [];
          self.resultForm.targets[0][0] = self.lang.validation.unknown;
        }
        window.display.reset(self.processResults);
        window.button.reset(obj);
      },
      copy(key) {
        let self = this;
        let textarea = $("#process-result-text-" + key);
        textarea.select();
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        alert(self.lang.alert.copied);
      },
      listFilter(key, type) {
        let temp = []
        let text = ''
        
        this.$set(this.resultForm.texts, key, '');
        
        if (type == "default") {
          temp = this.resultForm.targets[key];
        } else if (type == "unique") {
          temp = this.resultForm.targets[key].filter(function (x, i, self) {
            return self.indexOf(x) === i
          });
        } else if (type == "duplicate") {
          temp = this.resultForm.targets[key].filter(function (x, i, self) {
            return self.indexOf(x) !== self.lastIndexOf(x)
          });
        } else if (type == "duplicate_unique") {
          temp = this.resultForm.targets[key].filter(function (x, i, self) {
            return self.indexOf(x) === i && i !== self.lastIndexOf(x)
          });
        }
        for (let i in temp) {
          text += temp[i] + '\n'
        }
        this.resultForm.texts[key] = text;
      },
      showCheatSheet() {
        //let modal = window.open('', 'modal')
        //modal.document.write(createFormCheatSheet)
      }
    }
  }
</script>

<style>

  /** Extra Small Screen (col-xs) ********************************************************/
  @media (max-width: 767px) {

  }

  /** Small Screen (col-sm) **************************************************************/
  @media (min-width: 768px) {

  }

  /** Medium Screen (col-md) *************************************************************/
  @media (min-width: 992px) {

  }
  /** Large Screen (col-lg) **************************************************************/
  @media (min-width: 1200px) { /*元は1200px*/

  }

</style>
